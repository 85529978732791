<template>
<div>
  <AppSubHeader link='notificaciones' title='Notificaciones'/>
	<main class="m-content container mb-5">
		<div class="row">
			<div class="col-xl-6 mx-auto">
					<div class="m-portlet m-portlet--full-height m-portlet--mobile border-danger border-top">
						<div class="m-portlet__head">
				
							<div class="m-portlet__head-caption">
								<div class="m-portlet__head-title">
									<router-link to="/contratos" class="nav-link">
										<h3 class="m-portlet__head-text">
											Mis Notificaciones
										</h3>
									</router-link>
								</div>
							</div>
							
						</div>
					<!--Begin::Portlet-->
					<div class="m-portlet__body">
						<AlertList 
							:alert-list="alertsAll"
							@switch-alert="switchAlert"
							/>
						</div>
					</div>
					<!--End::Portlet-->
			</div>
		</div>
	</main>

</div>
</template>

<script>
import AppSubHeader from "@/components/layout/AppSubHeader.vue";
import AlertList from "@/components/alert/AlertList.vue";
import ScrollableModal from "@/components/ui/ScrollableModal";
import { mapActions, mapState, mapGetters} from "vuex";

export default {
  name:'Alert',
  components:{
			AppSubHeader,
			AlertList,
			ScrollableModal
	},
	computed: {
    ...mapState({
      alertsAll: state => state.alerts.all,
		}),
		...mapGetters({
    alert: 'alerts/currentAlert',
    })
	},
	mounted(){
		if(!this.alertsAll.length){
			this.$store.dispatch("alerts/getAll");
		}
	},
	methods: mapActions('alerts', [
    'switchAlert'
  ]),

}
</script>